import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material"
import {BrowserRouter} from "react-router-dom"
import {AuthProvider} from './contexts/AuthContext'
import * as Sentry from "@sentry/react"

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://5f3cd602f1433316acf0d58a9e87e4f8@o4507538532073472.ingest.de.sentry.io/4507803080130640",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 0.1, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["https://portal.virtualdivorce.com"],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"))

const theme = createTheme({
    palette: {
        primary: {
            main: "#020a4f",
            light: "#FFF",
        },
        secondary: {
            main: "#19857b",
        },
    },
})

root.render(
    // <React.StrictMode>
        <BrowserRouter>
            <AuthProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <App/>
                </ThemeProvider>
            </AuthProvider>
        </BrowserRouter>
    // </React.StrictMode>,
)
