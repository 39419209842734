import { useEffect } from 'react';

export const useScript = (url: string, isLoggedIn: boolean) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        let isAppended = false;

        if (isLoggedIn) {
            document.body.appendChild(script);
            isAppended = true;
        }

        return () => {
            if (isAppended) {
                document.body.removeChild(script);
            }
        };
    }, [url, isLoggedIn]);
};
