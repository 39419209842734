import React, { ComponentType, lazy, Suspense } from "react";
import {ProtectedRoute} from '../components/ProtectedRoute'
import ResetPasswordPage from '../pages/ResetPasswordPage'

const Loader = (Component: ComponentType) => (props: object) => (
  <Suspense fallback={<div></div>}>
    <Component {...props} />
  </Suspense>
);

const LoginPage = Loader(lazy(() => import("../pages/LoginPage")));
const PortalPage = Loader(lazy(() => import("../pages/PortalPage")));
const NotarizationPage = Loader(lazy(() => import("../pages/NotarizationPage")))

const routes = [
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <LoginPage />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "/set-new-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "/",
    element: (
        <ProtectedRoute>
          <PortalPage/>
        </ProtectedRoute>
    ),
  },
  {
    path: "/notarization",
    element: (
        <ProtectedRoute>
          <NotarizationPage />
        </ProtectedRoute>
    ),
  },
];

export default routes;
