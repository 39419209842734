import React, {useEffect} from "react"
import {
    Box,
    Button,
    Divider,
    Grid,
    Paper,
    TextField,
} from "@mui/material"
import * as Yup from "yup"
import {useFormik} from "formik"
import {useLocation, useNavigate, useSearchParams} from "react-router-dom"
import {resetPassword, setNewPassword} from '../api/auth'
import {toast} from 'react-toastify'
import {usePublicZohoChatHook} from '../hooks/PublicZohoChatHook'
import {STATES_DATA} from '../data'
import LoginHeader from '../components/LoginHeader'

const LoginPage = () => {
    const {pathname} = useLocation()
    const resetPasswordStage = pathname === "/set-new-password"
    const preResetStage = pathname === "/reset-password"
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const email = searchParams.get("email")?.replace(' ', '+')
    const state:string | undefined = searchParams.get("state")?.toUpperCase();
    usePublicZohoChatHook()

    const formik = useFormik({
        initialValues: preResetStage ? {
            email: email ? email : "",
        } : {
            email: email ? email : "",
            new_password: "",
            confirm_password: "",
            auth_code: ""
        },
        onSubmit: async (values) => {
            if (preResetStage) {
                resetPassword(values.email)
                    .then(() => {
                        navigate('/set-new-password')
                        toast.warning(
                            "Check your email for reset authorisation code",
                        )
                    })
                    .catch((err) => {
                            if(err.response?.data?.message) {
                                toast.error(err.response.data.message)
                            }
                        }
                    )
            }
            if (resetPasswordStage && values?.auth_code) {
                setNewPassword(values.email, values?.auth_code, values?.new_password)
                    .then(() => {
                        navigate('/login')
                        toast.success(
                            "Your password is successfully reset",
                        )
                    })
                    .catch((err) => {
                            if (err.response?.data?.message) {
                                toast.error(err.response.data.message)
                            }
                        }
                    )
            }
        },
        validationSchema: preResetStage ? Yup.object().shape({
            email: Yup.string()
                .required("Email/Email is required!")
                .email("Wrong email format"),
        }) : Yup.object().shape({
            email: Yup.string()
                .required("Email/Email is required!")
                .email("Wrong email format"),
            new_password: Yup.string().required("New password is required!").min(8, 'Password must be at least 8 characters'),
            confirm_password: Yup.string().required("Password confirmation is required!")
                .oneOf([Yup.ref('new_password'), ''], 'Passwords must match'),
            auth_code: Yup.string().required("Authorisation code is required!"),
        })
    })
    useEffect(() => {
        formik.resetForm()
    }, []) // eslint-disable-line

    return (
        <Grid container direction="column" spacing={2}>
            <Paper
                elevation={10}
                sx={{
                    padding: "0px",
                    width: {xs: "auto", sm: "450px"},
                    margin: {xs: "100px 20px", sm: "100px auto"},
                }}
            >
                <LoginHeader state={state || ''} />
                <Divider/>
                <Box
                    sx={{
                        padding: "8px 16px 0 16px",
                        width: {xs: "auto", sm: "450px"},
                        margin: "20px auto",
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sx={{mt: 1}}>
                                <TextField
                                    name="email"
                                    label="Email"
                                    error={!!formik.errors?.email}
                                    helperText={formik.errors.email}
                                    fullWidth
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    size="small"
                                />
                            </Grid>
                            {
                                resetPasswordStage && (
                                    <>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="auth_code"
                                                label="Authorisation code"
                                                type="text"
                                                error={!!formik.errors?.auth_code}
                                                helperText={formik.errors.auth_code}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.auth_code}
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="new_password"
                                                label="New Password"
                                                type="password"
                                                error={!!formik.errors?.new_password}
                                                helperText={formik.errors.new_password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.new_password}
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="confirm_password"
                                                label="Confirm Password"
                                                type="password"
                                                error={!!formik.errors?.confirm_password}
                                                helperText={formik.errors.confirm_password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.confirm_password}
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </>
                                )
                            }
                            <Grid
                                item
                                xs={12}
                                sx={{display: "flex", flexDirection: "column", rowGap: "8px"}}
                            >
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        backgroundColor: state ? STATES_DATA[state]?.secondaryColor : '',
                                        "&.MuiButtonBase-root:hover": {
                                            backgroundColor: state ? STATES_DATA[state]?.primaryColor : ''
                                        }
                                    }}
                                >
                                    {preResetStage && "Send me reset code"}
                                    {resetPasswordStage && "Reset Password"}
                                </Button>

                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Paper>
        </Grid>
    )
}

export default LoginPage
