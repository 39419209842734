import {Box, Typography} from '@mui/material'
import React from 'react'
import {STATES_DATA} from '../data'

const LoginHeader = ({state}: {state:string}) => {
    return(
        <Box
            sx={{
                display: "flex",
                borderRadius: "4px 4px 0 0",
                backgroundImage: "url(/login-header-bg.jpeg)",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                justifyContent: "space-around",
                alignItems: 'flex-end',
                position: 'relative'
            }}
        >
            <Box
                sx={{
                    borderRadius: "4px 4px 0 0",
                    height: state && STATES_DATA[state]?.plainLogo ? '170px' : '65px',
                    width: '100%',
                    py: 2,
                    backgroundColor: state ? STATES_DATA[state]?.primaryColor :  '',
                    opacity: 0.7,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',

                }}
            >
                {/*Here should be a child element, but because of an overlay on the bg image, this element is relocated below as a sibling element and positioned absolute*/}
            </Box>
            <Box sx={{
                height: '150px',
                display: "flex",
                flexDirection: "column",
                alignItems: 'center',
                position: 'absolute',
                top: '15px'
            }}>
                {
                    state && STATES_DATA[state]?.plainLogo && (
                        <img src={process.env.PUBLIC_URL + STATES_DATA[state]?.plainLogo as string} alt="logo" width={'100px'}
                             style={{width: '100px', marginBottom:  '10px'}}/>
                    )
                }
                <Typography
                    color={"primary.light"}
                    variant="h5"
                    sx={{textAlign: "center", fontWeight: 600}}
                >
                    {
                        state && STATES_DATA[state]?.siteName ? STATES_DATA[state].siteName : 'Divorce Online'
                    }
                </Typography>
            </Box>
        </Box>
    )
}
export default LoginHeader