import {CaseResponse} from "../models/case-response"
import {http} from "./index"
import {APP_CONFIG} from "../config/config"

export const getCaseInformation = async (
    email: string,
    isMobile: boolean,
): Promise<CaseResponse> => {
    const {data} = await http.post(
        `${APP_CONFIG.apiPrefix}/case-resolver?email=${encodeURIComponent(email)}&isMobile=${isMobile}`,
        null,
        {
            headers: {
                'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user_token') || '')
            }
        }
    )

    return data
}
