import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/LocalStorageHook";
import { loginUser } from "../api";

const AuthContext = createContext<{
  user: any;
  login: (username: string, password: string) => Promise<any>;
  setUserToken: (user: string) => void;
}>({ user: null, login: async () => {}, setUserToken: async () => {}, });

export const AuthProvider = (props: PropsWithChildren) => {
  const [user, setUser] = useLocalStorage("user_token", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (username: string, password: string) => {
    await loginUser(username, password).then((res: any) => {
      setUser(res?.accessToken);
      // These are credentials used by zoho to make an auth request and it fails in local environment due to their CORS policy
      // @ts-ignore
      window.ZohoHCAsapSettings = {
        userInfo: {
          jwtToken: res?.zohoAccessToken,
        },
      };
    });
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    navigate("/", { replace: true });
  };
  const setUserToken = (user:any) => {
    setUser(user);
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      setUserToken
    }),
    [user] // eslint-disable-line
  );
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
