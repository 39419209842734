import { Navigate } from "react-router-dom";
import {PropsWithChildren, ReactElement} from 'react'
import {useAuth} from '../contexts/AuthContext'
import {useScript} from '../hooks/UseScriptHook'
// @ts-ignore
import {jwtDecode} from 'jwt-decode'

export const ProtectedRoute = ({ children }:PropsWithChildren):ReactElement => {
    const { user } = useAuth();
    useScript('https://desk.zoho.com/portal/api/web/inapp/907166000001523047?orgId=829908505', !!user)

    let decodedToken = {email: '', caseId : '', fullName: '', exp: 0}
    if(user) {
        decodedToken = jwtDecode<any>(user)
    }

    // @ts-ignore
    window.ZohoHCAsapSettings = {
        userInfo: {
            jwtToken: user
        },
        ticketsSettings : {
            preFillFields : {
                "email" : {
                    defaultValue : decodedToken.email
                },
                "Case ID -" : {
                    defaultValue : decodedToken.caseId
                },
                "contactId" : {
                    defaultValue : decodedToken.fullName
                }
            }
        },
    };

    if (user && decodedToken?.exp) {
        if (Date.now() >= decodedToken.exp * 1000) {
            return <Navigate to="/login"/>
        }
    }

    if (!user) {
        return <Navigate to="/login" />;
    }
    return children as ReactElement
};