import {http} from './index'
import {CaseResponse} from '../models/case-response'
import {APP_CONFIG} from '../config/config'
import {RegisterResponse} from '../models/user-response'

export const loginUser = async (
    email: string,
    password: string
): Promise<CaseResponse> => {
    const { data } = await http.post(
        `${APP_CONFIG.apiPrefix}/login`,
        {
            email,
            password
        }
    );

    return data;
};


export const registerUser = async (
    email: string,
    password: string
): Promise<RegisterResponse> => {
    const { data } = await http.post(
        `${APP_CONFIG.apiPrefix}/register`,
        {
            email,
            password
        }
    );

    return data;
};

export const resetPassword = async (
    email: string,
): Promise<RegisterResponse> => {
    const { data } = await http.post(
        `${APP_CONFIG.apiPrefix}/reset-password`,
        {
            email
        }
    );

    return data;
};

export const setNewPassword = async (
    email: string,
    authCode: string,
    newPassword: string
): Promise<RegisterResponse> => {
    const { data } = await http.post(
        `${APP_CONFIG.apiPrefix}/set-new-password`,
        {
            email,
            authCode,
            newPassword
        }
    );

    return data;
};