import { useEffect } from 'react';

export const usePublicZohoChatHook = () => {
    useEffect(() => {
        const scriptPublic = document.createElement('script')

        scriptPublic.src = "https://salesiq.zohopublic.com/widget?widgetcode=siq52098ddca2990a52e72eaf7f0167ca06777fd8a159602c45aa1a2f1e47588232"
        scriptPublic.async = true
        scriptPublic.id="zsiqchat"

        document.body.appendChild(scriptPublic)

        return () => {
            document.body.removeChild(scriptPublic)
        }
    }, [])
};