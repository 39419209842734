import {useRoutes} from "react-router-dom"
import React from "react"
import routes from "./router/router"
import {ToastContainer} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const App = () => {
    const content = useRoutes(routes)

    return <div>
        {content}
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
            style={{maxWidth: '500px'}}
        />
    </div>
}
export default App
