import Axios, {AxiosError} from "axios"
import * as Sentry from "@sentry/react"
import {invalidPasswordMsg} from '../constants'

export const http = Axios.create({
  baseURL: "https://1ig4vcqkvc.execute-api.us-east-1.amazonaws.com/v1",
});

http.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError | any) => {
      if (error.response) {
          if(error.response?.data?.message === invalidPasswordMsg) {
              return false
          } else {
              Sentry.captureException(error)
          }

      }

      return Promise.reject(error)
    }
);


export const httpDoc = Axios.create({
  baseURL: "https://app.virtualdivorce.com/api",
});

export * from "./case";
export * from './auth'